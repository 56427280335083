import { ChangeDetectorRef, DestroyRef, inject, Pipe, PipeTransform } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LocalizeRouterService } from '@owain/ngx-transloco-router/localize-router.service';

const VIEW_DESTROYED_STATE = 128;

@Pipe({
  name: 'localize',
  pure: false, // required to update the value when the promise is resolved
  standalone: true,
})
export class LocalizeRouterPipe implements PipeTransform {
  private readonly localizeRouterService: LocalizeRouterService = inject(LocalizeRouterService);
  private readonly changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  private value: string | any[] = '';
  // @ts-ignore
  private lastKey: string | any[];
  // @ts-ignore
  private lastLanguage: string;

  /**
   * CTOR
   */
  constructor() {
    this.localizeRouterService.routerEvents.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.transform(this.lastKey);
    });
  }

  /**
   * Transform current url to localized one
   */
  transform(query: string | any[]): string | any[] {
    if (!query || query.length === 0 || !this.localizeRouterService.parser.currentLang) {
      return query;
    }
    if (
      this.equals(query, this.lastKey) &&
      this.equals(this.lastLanguage, this.localizeRouterService.parser.currentLang)
    ) {
      return this.value;
    }
    this.lastKey = query;
    this.lastLanguage = this.localizeRouterService.parser.currentLang;

    /** translate key and update values */
    this.value = this.localizeRouterService.translateRoute(query);
    this.lastKey = query;
    // if view is already destroyed, ignore firing change detection
    const view = (this.changeDetectorRef as any)._view;
    if (view && view.state && VIEW_DESTROYED_STATE) {
      return this.value;
    }
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    }, 0);

    return this.value;
  }

  equals(o1: any, o2: any): boolean {
    if (o1 === o2) {
      return true;
    }
    if (o1 === null || o2 === null) {
      return false;
    }
    if (o1 !== o1 && o2 !== o2) {
      return true; // NaN === NaN
    }
    const t1 = typeof o1,
      t2 = typeof o2;
    let length: number, key: any, keySet: any;

    if (t1 === t2 && t1 === 'object') {
      if (Array.isArray(o1)) {
        if (!Array.isArray(o2)) {
          return false;
        }
        if ((length = o1.length) === o2.length) {
          for (key = 0; key < length; key++) {
            if (!this.equals(o1[key], o2[key])) {
              return false;
            }
          }
          return true;
        }
      } else {
        if (Array.isArray(o2)) {
          return false;
        }
        keySet = Object.create(null);
        for (key in o1) {
          if (o1.hasOwnProperty(key)) {
            if (!this.equals(o1[key], o2[key])) {
              return false;
            }
            keySet[key] = true;
          }
        }
        for (key in o2) {
          if (o2.hasOwnProperty(key)) {
            if (!(key in keySet) && typeof o2[key] !== 'undefined') {
              return false;
            }
          }
        }
        return true;
      }
    }
    return false;
  }
}
